import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 91 85" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M77.316 48.583v30.22c0 5.637-.52 6.173-6 6.187-17.465.013-34.943.013-52.408 0-5.48 0-5.973-.523-5.987-6.173-.013-8.937-.013-17.874 0-26.824 0-2.021.083-3.698-2.725-4.152-1.274-.206-2.398-1.939-3.398-3.148-1.835-2.2-3.547-4.482-5.26-6.779-2.3-3.08-2.11-4.248 1.178-6.805 9.302-7.218 18.63-14.395 27.958-21.585 2.63-2.021 5.22-4.097 7.863-6.077 4.657-3.479 8.561-3.547 13.15-.028 11.972 9.17 23.903 18.41 35.82 27.649 3.275 2.53 3.439 3.918 1.028 7.135-1.85 2.475-3.602 5.046-5.685 7.3-1.273 1.376-3.082 2.27-4.643 3.37-.288-.083-.59-.193-.89-.29ZM32.058 78.542v-4.153c0-6.764.055-13.514-.014-20.279-.027-3.093 1.15-4.702 4.425-4.66 5.821.082 11.643.068 17.465.013 2.904-.027 4.123 1.361 4.095 4.207-.054 5.156-.013 10.312-.013 15.468 0 9.788 0 9.706 9.862 9.459 2.33-.055 2.904-.95 2.89-3.08-.068-10.202.014-20.39-.095-30.59-.014-1.21-.603-2.82-1.493-3.534a871.189 871.189 0 0 0-22.232-17.186c-.781-.577-2.713-.591-3.493-.014-7.507 5.596-14.904 11.343-22.246 17.159-.85.673-1.616 2.048-1.63 3.107-.123 10.531-.055 21.076-.096 31.622 0 1.704.548 2.516 2.37 2.46 3.191-.068 6.37 0 10.205 0Zm50.244-42.855c-.67-.715-1-1.182-1.438-1.526-10.93-8.455-21.903-16.856-32.807-25.352-2.246-1.746-4-1.375-6.082.26-8.547 6.696-17.177 13.282-25.766 19.909-2.685 2.076-5.342 4.193-8.287 6.49 1.424 1.773 2.589 3.23 3.849 4.784 1.685-1.265 3.055-2.269 4.397-3.3 8.506-6.558 17.068-13.047 25.465-19.73 2.643-2.117 4.698-2.075 7.219.083 3.123 2.668 6.479 5.06 9.74 7.576 6.588 5.073 13.177 10.16 20.012 15.426a339.575 339.575 0 0 1 3.698-4.62Zm-30.985 42.84V55.926c-3.684 0-7.095-.137-10.479.096-.808.055-2.178 1.361-2.191 2.131-.178 6.064-.165 12.14-.014 18.204.014.742 1.192 2.02 1.89 2.062 3.507.234 7.027.11 10.794.11Z"
			fill="#262C9D"
		/>
		<path
			d="M32.057 78.54c-3.821 0-7.013-.068-10.205.028-1.821.055-2.383-.756-2.37-2.46.042-10.546-.027-21.077.097-31.622.013-1.059.78-2.434 1.63-3.107 7.342-5.816 14.739-11.563 22.245-17.159.767-.577 2.713-.563 3.493.014A840.645 840.645 0 0 1 69.18 41.42c.89.715 1.48 2.323 1.493 3.533.124 10.188.028 20.39.096 30.59.014 2.132-.575 3.012-2.89 3.08-9.862.262-9.862.33-9.862-9.459 0-5.155-.042-10.311.013-15.467.028-2.846-1.178-4.234-4.096-4.207-5.821.055-11.643.069-17.465-.014-3.274-.055-4.452 1.568-4.424 4.661.068 6.764.014 13.515.014 20.28v4.124Z"
			fill="#75E68E"
		/>
		<path
			d="M82.304 35.688c-1.274 1.595-2.398 2.984-3.713 4.62-6.835-5.266-13.424-10.353-20.012-15.426-3.26-2.516-6.617-4.908-9.74-7.575-2.52-2.159-4.561-2.2-7.219-.083-8.397 6.696-16.958 13.171-25.465 19.73-1.342 1.044-2.712 2.048-4.397 3.299-1.26-1.567-2.424-3.011-3.849-4.785 2.945-2.31 5.603-4.413 8.288-6.489 8.588-6.64 17.218-13.212 25.766-19.908 2.082-1.636 3.835-2.007 6.082-.261 10.903 8.497 21.876 16.91 32.807 25.352.438.33.78.812 1.452 1.526Z"
			fill="#FF7354"
		/>
		<path
			d="M51.316 78.53c-3.78 0-7.3.11-10.807-.096-.699-.041-1.877-1.334-1.89-2.063-.151-6.063-.165-12.14.013-18.203.028-.77 1.384-2.062 2.192-2.13 3.383-.248 6.808-.097 10.479-.097.014 7.548.014 14.821.014 22.589Z"
			fill="#D9FFBB"
		/>
		<path
			d="M45.547 46.512c-4.23.003-7.569-3.36-7.566-7.62.003-4.255 3.354-7.62 7.58-7.614 4.216.006 7.549 3.395 7.537 7.659-.012 4.198-3.377 7.572-7.55 7.575Zm2.742-10.636c-.366-.22-.652-.474-.983-.572-.711-.213-1.298-.477-1.1-1.366-1.016-.15-.984-.142-1.205.688-.056.21-.354.42-.587.503-.954.35-1.52 1.037-1.583 2.015-.065 1.055.313 1.736 1.3 2.175.601.27 1.242.456 1.87.666.458.154.845.4.742.94-.11.568-.605.743-1.084.64-.7-.155-1.372-.44-2.131-.69-1.045.814-1.01 1.214.28 1.827a4.1 4.1 0 0 0 .691.252c.372.098.582.264.605.705.01.178.313.424.526.483.327.092.581-.02.664-.465.05-.27.298-.608.543-.717 1.154-.507 1.641-1.138 1.641-2.281 0-1.103-.469-1.748-1.623-2.202-.617-.243-1.26-.43-1.845-.734-.221-.116-.466-.525-.416-.738.05-.223.442-.522.679-.516a4.099 4.099 0 0 1 1.552.33c.46.206.726.162.971-.256.113-.195.257-.364.493-.687Z"
			fill="#262C9D"
		/>
		<path
			d="M48.29 35.875c-.236.326-.381.492-.49.682-.245.42-.51.462-.971.255a4.098 4.098 0 0 0-1.553-.33c-.236-.005-.626.294-.679.516-.047.21.195.62.416.738.585.305 1.228.492 1.845.735 1.151.456 1.624 1.099 1.624 2.201 0 1.144-.49 1.775-1.642 2.281-.245.11-.493.448-.543.717-.082.445-.336.557-.664.466-.212-.06-.514-.306-.525-.483-.024-.442-.233-.608-.605-.706a4.098 4.098 0 0 1-.691-.251c-1.29-.61-1.326-1.01-.28-1.828.755.252 1.428.536 2.13.69.479.107.975-.068 1.084-.64.103-.54-.283-.785-.74-.94-.63-.212-1.267-.4-1.872-.666-.986-.438-1.364-1.12-1.3-2.174.063-.978.63-1.666 1.583-2.015.233-.086.531-.296.587-.504.222-.83.19-.838 1.205-.687-.198.889.386 1.155 1.1 1.366.329.1.615.355.98.577Z"
			fill="#75E68E"
		/>
	</svg>
);

export default SvgHouse;
