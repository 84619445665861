import { Tab } from '@headlessui/react';
import { Modal } from '@ui/components/Modal';
import { Button } from '@ui/components/inputs/Button';
import { LinkButton, type PrimaryLinkProps } from '@ui/components/inputs/LinkButton';
import { clsx } from '@ui/utils/styles';
import React, { useState } from 'react';
import { useOptionalOnboarding } from '~/onboarding/utils';
import { DecreasingNeeds } from '~/routes/_index/components/discover/DecreasingNeeds';
import { IncreasingNeeds } from '~/routes/_index/components/discover/IncreasingNeeds';
import { LegacyNeeds } from '~/routes/_index/components/discover/LegacyNeeds';
import { useOptionalUser } from '~/shared/utils';

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <div className={'md:px-12'}>{children}</div>;
};

const WrapperLink: React.FC<{ text: string; linkProps: PrimaryLinkProps }> = ({
	text,
	linkProps,
}) => {
	return (
		<Wrapper>
			<div className={'relative max-w-md py-2'}>
				<LinkButton mode="accent" className={'text-lg tracking-widest'} {...linkProps}>
					{text}
				</LinkButton>
			</div>
		</Wrapper>
	);
};

type ModalType = 'increasing' | 'decreasing' | 'legacy' | '';
const Tabs: ModalType[] = ['increasing', 'decreasing', 'legacy'];

export default function Index() {
	const [modal, setModal] = useState<ModalType>('');
	const onboarding = useOptionalOnboarding();
	const user = useOptionalUser();

	const off = () => setModal('');
	const on = (type: ModalType) => () => setModal(type);

	const fromLinkState = onboarding && !user;
	return (
		<>
			<div
				className={'flex items-center gap-8 mx-auto max-w-[1440px] bg-white overflow-hidden py-4'}
			>
				<div className={'flex-1 px-8 text-primary-main'}>
					<Wrapper>
						<h1 className={'leading-2 py-4 font-heading text-5xl font-semibold tracking-wide'}>
							Life insurance{' '}
							<span className={'text-secondary-main'}>
								needs
								<br />
								change
							</span>
							&nbsp;over time
						</h1>

						<p className={'font-semibold text-lg'}>
							Your needs will likely go up over time, then down.
						</p>
						<ul className={'list-disc pl-8'}>
							<li>Your needs are unique. So is your benefit curve.</li>
							<li>
								Mapping your benefit curve helps you picture and plan your financial future.
								It&apos;s easy!
							</li>
						</ul>
					</Wrapper>

					<div className={'pt-8 mt-4 relative flex flex-col gap-2 md:block max-w-lg'}>
						<Button
							className={'btn-sm text-accent text-xs md:absolute top-0 left-[15%]'}
							onClick={on('increasing')}
						>
							Increasing Needs
						</Button>
						<Button
							className={'btn-sm text-accent text-xs md:absolute top-[15%] right-[25%]'}
							onClick={on('decreasing')}
						>
							Decreasing Needs
						</Button>
						<Button
							className={'btn-sm text-accent text-xs md:absolute bottom-[45%] right-[2%]'}
							onClick={on('legacy')}
						>
							Leaving a Legacy
						</Button>
						<div className={'hidden md:block'}>
							<img
								className={'aspect-auto'}
								src={'/images/MBC_Graph_No_Buttons.png'}
								alt={'Graph'}
							/>
						</div>
					</div>

					<WrapperLink
						text="Let's Go"
						linkProps={{ to: fromLinkState ? '/onboarding' : '/account' }}
					/>
					{fromLinkState && (
						<WrapperLink text={'Log in'} linkProps={{ to: '/login', mode: 'ghost' }} />
					)}
				</div>
				<div className={'hidden md:block flex-1'}>
					<img
						className={'aspect-auto h-fit'}
						src={'/images/kmodo_banner_Image.jpg'}
						alt={'Family'}
					/>
				</div>
			</div>
			<Modal isOpen={Boolean(modal)} onClose={off} className={'max-w-7xl px-8'}>
				<Tab.Group
					selectedIndex={Tabs.findIndex((t) => t === modal)}
					onChange={(i) => setModal(Tabs[i])}
				>
					<Tab.List className={'tabs tabs-lg tabs-bordered'}>
						<Tab as={React.Fragment}>
							{({ selected }) => (
								<button
									type="button"
									className={clsx('tab', {
										'tab-active': selected,
									})}
								>
									Increasing Needs
								</button>
							)}
						</Tab>
						<Tab as={React.Fragment}>
							{({ selected }) => (
								<button
									type="button"
									className={clsx('tab', {
										'tab-active': selected,
									})}
								>
									Decreasing Needs
								</button>
							)}
						</Tab>
						<Tab as={React.Fragment}>
							{({ selected }) => (
								<button
									type="button"
									className={clsx('tab', {
										'tab-active': selected,
									})}
								>
									Leaving a Legacy
								</button>
							)}
						</Tab>
					</Tab.List>
					<Tab.Panels className={'min-h-[70vh]'}>
						<Tab.Panel>
							<IncreasingNeeds />
						</Tab.Panel>
						<Tab.Panel>
							<DecreasingNeeds />
						</Tab.Panel>
						<Tab.Panel>
							<LegacyNeeds />
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</Modal>
		</>
	);
}
