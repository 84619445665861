import { FloatingCard } from '@ui/components/FloatingCard';
import type React from 'react';
import { ArrowTable } from '~/routes/_index/components/discover/ArrowTable';
import { DiscoverTabLayout } from '~/routes/_index/components/discover/DiscoverTabLayout';
import { Baby, Expenses, Graduate, House } from '~/shared/svgs';

type IncreasingNeedsProps = {};

export const IncreasingNeeds: React.FC<IncreasingNeedsProps> = () => {
	return (
		<DiscoverTabLayout>
			<section>
				<div className={'py-4 text-4xl'}>
					<h1 className={'font-heading font-semibold'}>
						Increasing coverage needs
						<br />
						<span className={'font-light'}>for new life events</span>
					</h1>
				</div>
			</section>

			<div className={'flex flex-1 pr-24'}>
				<div className={'flex-1'}>
					<ArrowTable
						leftHeader={
							<span>
								<b>Life Changing</b> Event
							</span>
						}
						rightHeader={
							<span>
								<b>Why</b> It Matters
							</span>
						}
						rows={[
							{
								icon: <Expenses className={'h-10 w-10'} />,
								left: 'Income grows',
								right: 'Expenses rise; More income to protect',
							},
							{
								icon: <Baby className={'h-10 w-10'} />,
								left: 'Having children',
								right: 'Protect their lifestyle',
							},
							{
								icon: <Graduate className={'h-10 w-10'} />,
								left: 'College education',
								right: 'Ensure commitment to pay is fulfilled',
							},
							{
								icon: <House className={'h-10 w-10'} />,
								left: 'Mortgages',
								right: 'Ensure your family stays in the home',
							},
						]}
					/>
				</div>
				<div className={'relative ml-24 flex-1'}>
					<img
						className={'absolute left-0 top-36 aspect-auto'}
						src={'/images/kmodo_IncreasingNeeds_House.jpg'}
						alt={'House'}
					/>

					<img
						className={'absolute left-80 top-36 aspect-auto'}
						src={'/images/kmodo_IncreasingNeeds_BabyFeet.jpg'}
						alt={'Baby'}
					/>

					<FloatingCard showDividedLine className={'absolute left-12 top-0 w-96'}>
						<div>
							<h3 className={'font-heading text-lg font-semibold'}>
								Secure Coverage Today
								<br />
								For Coverage Needs Expected To Grow
							</h3>
							<ul className={'list-disc px-4'}>
								<li>Your benefit curve shows when your needs are expected to increase</li>
								<li>Cover planned event such as more children</li>
								<li>Lock down protection today in case your health changes in the future</li>
							</ul>
						</div>
					</FloatingCard>
				</div>
			</div>
		</DiscoverTabLayout>
	);
};
