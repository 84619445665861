import { FloatingCard } from '@ui/components/FloatingCard';
import type React from 'react';
import { ArrowTable } from '~/routes/_index/components/discover/ArrowTable';
import { DiscoverTabLayout } from '~/routes/_index/components/discover/DiscoverTabLayout';
import { Baby, Graduate, House, ParkBench } from '~/shared/svgs';

type DecreasingNeedsProps = {};

export const DecreasingNeeds: React.FC<DecreasingNeedsProps> = () => {
	return (
		<DiscoverTabLayout>
			<section>
				<div className={'py-4 text-4xl'}>
					<h1 className={'font-heading font-semibold'}>
						Decreasing coverage needs
						<br />
						<span className={'font-light'}>as your life changes</span>
					</h1>
				</div>
			</section>

			<div className={'flex flex-1 pb-16 pr-16'}>
				<div className={'flex-1'}>
					<ArrowTable
						leftHeader={
							<span>
								<b>Life Changing</b> Event
							</span>
						}
						rightHeader={
							<span>
								<b>Why</b> It Matters
							</span>
						}
						rows={[
							{
								icon: <ParkBench className={'h-10 w-10'} />,
								left: 'Retirement is near',
								right: 'Amount of future income to protect decreases',
							},
							{
								icon: <Baby className={'h-10 w-10'} />,
								left: 'Children are now independent',
								right: 'No longer need coverage for child care',
							},
							{
								icon: <Graduate className={'h-10 w-10'} />,
								left: 'Child graduates from college',
								right: 'Extra coverage to fund tuition is no longer needed',
							},
							{
								icon: <House className={'h-10 w-10'} />,
								left: 'Mortgage is paid off',
								right: 'Extra protection not needed anymore',
							},
						]}
					/>
				</div>
				<div className={'relative min-w-max flex-1'}>
					<img
						className={'absolute left-36 top-40 aspect-auto'}
						src={'/images/kmodo_DecreasingNeeds_Grad.jpg'}
						alt={'Grad'}
					/>

					<img
						className={'absolute left-80 top-28 aspect-auto'}
						src={'/images/kmodo_DecreasingNeeds_Chairs.png'}
						alt={'Chairs'}
					/>

					<FloatingCard showDividedLine className={'absolute left-16 top-0 w-96'}>
						<div>
							<h3 className={'font-heading text-lg font-semibold'}>
								Optimize Your Protection And Cost
							</h3>
							<ul className={'list-disc px-4'}>
								<li>Use term products for temporary needs</li>
								<li>Ladder the term and coverage amount according to your benefit curve</li>
							</ul>
						</div>
					</FloatingCard>
				</div>
			</div>
		</DiscoverTabLayout>
	);
};
