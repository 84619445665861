import type { ReactNode } from 'react';
import type React from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';

interface ArrowTableProps {
	leftHeader: ReactNode;
	rightHeader: ReactNode;
	rows: { icon?: ReactNode; left: ReactNode; right: ReactNode }[];
	arrowColor?: string;
}

export const ArrowTable: React.FC<ArrowTableProps> = (props) => {
	const { leftHeader, rightHeader, rows, arrowColor } = props;

	const rowClass = 'flex items-center gap-4 justify-between py-4 border-b border-background-dark';

	return (
		<div>
			<div className={rowClass}>
				<div className={'flex-[3_3_0%] text-xl font-semibold'}>{leftHeader}</div>
				<div className={'flex-1'} />
				<div className={'flex-[3_3_0%] text-xl font-semibold'}>{rightHeader}</div>
			</div>

			{rows.map((r, i) => {
				return (
					<div className={rowClass} key={i}>
						<div className={'flex flex-[3_3_0%] items-center gap-2'}>
							{r.icon && (
								<div className={'rounded-full border-2 border-secondary-main bg-white p-1'}>
									{r.icon}
								</div>
							)}
							<p className={'font-semibold'}>{r.left}</p>
						</div>
						<div className={'flex flex-1 items-center justify-center'}>
							<BiRightArrowAlt
								className={'h-12 w-12 text-accent'}
								style={{
									color: arrowColor,
								}}
							/>
						</div>

						<div className={'flex-[3_3_0%]'}>
							<p>{r.right}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};
