import { FloatingCard } from '@ui/components/FloatingCard';
import type React from 'react';
import { ArrowTable } from '~/routes/_index/components/discover/ArrowTable';
import { DiscoverTabLayout } from '~/routes/_index/components/discover/DiscoverTabLayout';
import { DollarSign, Graduate, HandShield } from '~/shared/svgs';

type LegacyNeedsProps = {};

export const LegacyNeeds: React.FC<LegacyNeedsProps> = () => {
	return (
		<DiscoverTabLayout>
			<section>
				<div className={'py-8 text-4xl'}>
					<h1 className={'font-heading font-semibold'}>
						Leave a legacy
						<br />
						<span className={'font-light'}>to those you care about</span>
					</h1>
				</div>
			</section>

			<div className={'flex flex-1'}>
				<div className={'flex-1'}>
					<ArrowTable
						leftHeader={
							<span>
								<b>Moments In</b> Your Life
							</span>
						}
						rightHeader={
							<span>
								<b>Why</b> It Matters
							</span>
						}
						rows={[
							{
								icon: <HandShield className={'h-10 w-10'} />,
								left: 'Donations to charity',
								right: 'Benefit replaces your time and money contributed',
							},
							{
								icon: <DollarSign className={'h-10 w-10'} />,
								left: 'Pay income tax on retirement account',
								right:
									'Inherited retirement accounts are subject to income tax which life insurance could cover',
							},
							{
								icon: <Graduate className={'h-10 w-10'} />,
								left: 'Help out a relative or friend',
								right: 'Fund a retirement account, upgrade housing, pay off debts',
							},
						]}
					/>
				</div>
				<div className={'relative ml-32 min-w-max flex-1'}>
					<img
						className={'absolute left-48 top-36 aspect-auto'}
						src={'/images/kmodo_Legacy_BabyHands.jpg'}
						alt={'Hands'}
					/>

					<img
						className={'absolute left-0 top-40 aspect-auto'}
						src={'/images/kmodo_Legacy_Grandma.jpg'}
						alt={'Grandma'}
					/>

					<FloatingCard showDividedLine className={'absolute left-8 top-0 w-96'}>
						<div>
							<h3 className={'font-heading text-lg font-semibold'}>
								Permanent Life Insurance Best Supports Legacy Wishes
							</h3>
							<ul className={'list-disc px-4'}>
								<li>Lifetime coverage</li>
								<li>Build tax-advantaged cash value</li>
								<li>Use the cash value to supplement your retirement income</li>
							</ul>
						</div>
					</FloatingCard>
				</div>
			</div>
		</DiscoverTabLayout>
	);
};
