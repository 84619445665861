import type { ReactNode } from 'react';
import type React from 'react';

interface DiscoverTabLayoutProps {
	children: ReactNode;
}

export const DiscoverTabLayout: React.FC<DiscoverTabLayoutProps> = ({ children }) => {
	return (
		<div className={'flex flex-1 flex-col justify-between'}>
			<div className={'flex flex-1 flex-col'}>{children}</div>
		</div>
	);
};
