import type { HTMLAttributes } from 'react';
import type React from 'react';
import { clsx } from '../utils/styles';
import { useTheme } from '../theme';

interface DividedLineProps extends HTMLAttributes<HTMLDivElement> {
	colors?: {
		color: string;
		flex: number;
	}[];
}

export const DividedLine: React.FC<DividedLineProps> = (props) => {
	const { className, colors: propColors, ...rest } = props;
	const theme = useTheme();

	const colors = propColors || [
		{ color: theme.colors.primary.main, flex: 1 },
		{ color: theme.colors.tertiary.main, flex: 1 },
		{ color: theme.colors.secondary.main, flex: 1 },
		{ color: theme.colors.background.main, flex: 1 },
	];

	return (
		<div className={clsx('flex h-2 w-full', className)} {...rest}>
			{colors.map((x, i) => {
				return <div key={i} style={{ backgroundColor: x.color, flex: x.flex }} />;
			})}
		</div>
	);
};
