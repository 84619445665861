import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDollarSign = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M14 28.086c-7.828.006-14.005-6.194-14-14.048C.005 6.194 6.205-.011 14.027 0c7.8.01 13.966 6.26 13.944 14.12C27.95 21.86 21.723 28.08 14 28.085Zm5.074-19.609c-.678-.404-1.208-.874-1.82-1.054-1.316-.393-2.403-.88-2.037-2.518-1.879-.279-1.819-.262-2.228 1.267-.104.388-.656.776-1.087.929-1.764.644-2.813 1.911-2.928 3.714-.12 1.944.58 3.2 2.403 4.01 1.115.496 2.3.84 3.463 1.228.847.284 1.563.738 1.372 1.732-.203 1.048-1.12 1.37-2.005 1.18-1.295-.285-2.54-.809-3.944-1.273-1.933 1.502-1.868 2.24.52 3.37.409.191.84.35 1.277.464.688.18 1.076.486 1.12 1.3.016.328.579.781.972.89.607.17 1.076-.038 1.23-.857.092-.497.55-1.12 1.004-1.322 2.136-.934 3.037-2.097 3.037-4.206 0-2.032-.868-3.222-3.004-4.058-1.142-.448-2.332-.792-3.414-1.355-.41-.213-.863-.966-.77-1.36.093-.41.82-.961 1.256-.95.967.022 1.994.213 2.873.606.852.383 1.344.3 1.797-.47.208-.36.476-.671.913-1.267Z"
			fill="#262C9D"
		/>
		<path
			d="M19.074 8.477c-.437.6-.705.907-.907 1.256-.453.776-.945.852-1.797.47-.88-.393-1.906-.584-2.873-.606-.437-.011-1.158.54-1.256.95-.088.388.36 1.142.77 1.36 1.082.563 2.272.907 3.414 1.355 2.13.841 3.004 2.026 3.004 4.058 0 2.108-.907 3.272-3.037 4.206-.453.202-.912.825-1.005 1.322-.153.82-.623 1.027-1.229.857-.393-.109-.95-.562-.972-.89-.044-.814-.432-1.12-1.12-1.3a7.605 7.605 0 0 1-1.278-.464c-2.387-1.125-2.453-1.863-.519-3.37 1.398.464 2.644.988 3.944 1.272.885.197 1.802-.125 2.004-1.18.191-.994-.524-1.447-1.37-1.731-1.164-.393-2.344-.737-3.464-1.229-1.824-.808-2.523-2.065-2.403-4.01.115-1.802 1.163-3.069 2.928-3.713.431-.159.983-.547 1.087-.929.41-1.53.35-1.546 2.228-1.267-.366 1.638.716 2.13 2.037 2.518.607.186 1.137.655 1.814 1.065Z"
			fill="#75E68E"
		/>
	</svg>
);

export default SvgDollarSign;
