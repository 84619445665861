import type { HTMLAttributes } from 'react';
import type React from 'react';
import { clsx } from '../utils/styles';
import {DividedLine} from "./DividedLine";

interface FloatingCardProps extends HTMLAttributes<HTMLDivElement> {
	showDividedLine?: boolean;
}

export const FloatingCard: React.FC<FloatingCardProps> = (props) => {
	const { children, className, showDividedLine, ...rest } = props;
	const cls = clsx('card bg-white shadow-2xl rounded', className);

	return (
		<div className={cls} {...rest}>
			{showDividedLine && <DividedLine className={'h-2'} />}
			<div className="card-body p-4">{children}</div>
		</div>
	);
};
